import './App.css';
import flag from "./flag.png";
import step2 from "./step_2.png";

function App() {
  return (
    <div>
      <img src={flag} alt="Flag of Saskatchewan" width="60" />
      <h1>Saskatchewan United</h1>
      <p>Entitled political insiders think they can tell us how to make our private medical decisions.</p>
      <p>This is a grassroots initiative to demand openness and honesty from our elected leaders.</p>
      <p>Our politicians have sown too much division. We need to bring this province together again.</p>
      <h2>That's why we're asking Nadine Wilson to take the first step and resign from the Saskatchewan Legislature immediately</h2>
      <img src={step2} alt="A cartoon illustrating our Two-Point Plan to Unite Saskatchewan" width="400" />
      <h1>Our Two-Point Plan to Unite Saskatchewan:</h1>
      <h2>1. Nadine Wilson resigns</h2>
      <h2>2. Voters get to choose someone who actually wants to be an MLA</h2>
    </div>
  );
}

export default App;
